import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Page from "../components/Page";
import List from "../components/List";
import Tabs from "../components/Tabs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CalendarMultipleCheck, AccountMultiple, TrophyOutline } from "mdi-material-ui";
import withRoot from "../utils/withRoot";
import Avatar from "@material-ui/core/Avatar";
import Img from 'gatsby-image'

const styles = theme => ({
    angles: {
      color: theme.palette.secondary.light,
      opacity: 0.5,
    },
    avatar: {
      width: "160px",
      height: "160px",
      marginBottom: "40px",
      backgroundColor: theme.palette.primary.light,
    },
    logo: {
      maxWidth: "500px",
      margin: "30px auto",
    },
    text: {
      textAlign: "center",
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    tabs: {
      marginTop: "40px",
      marginBottom: "40px",
    },
  }),
  Home = props => {
    const {
      classes,
      data: {
        logo,
        gonzoImage, 
        garrettImage,
        gavinImage,
        gabeImage,
        avLogo,
        allscriptsLogo,
        apmLogo,
        appuriLogo,
        mulesoftLogo,
        studykikLogo,
        Projects: { edges: projects },
        Products: { edges: products },
        Services: { edges: services },
        Members: { edges: members },
        Basic: {
          siteMetadata: {
            domain,
            company,
            defaultTitle,
            preamble,
            postamble,
            defaultDescription,
            contact: { email },
          },
        },
      },
    } = props;
    return (
      <Page>
        <SEO title={defaultTitle}>
          <meta name="description" content={defaultDescription} />
          <link rel="canonical" href={domain} />
        </SEO>
        <div className={classes.text}>
          <a href={domain}>
            <Img fluid={logo.childImageSharp.fluid} className={classes.logo}/>
          </a>

          <Typography paragraph gutterBottom variant="body1" component="span">
            {preamble}
          </Typography>
          <Typography paragraph gutterBottom variant="body1" component="span">
            {defaultDescription}
          </Typography>
        </div>
        <div className={props.classes.tabs}>
          <Tabs
            items={[

              /*["Our Products", <Robot />, <List items={products} />],*/
              ["Our Services", <TrophyOutline />, <List items={services} images={{}} />],
              ["Recent Projects", <CalendarMultipleCheck />, <List items={projects} images={{
                avLogo,
                allscriptsLogo,
                apmLogo,
                appuriLogo,
                mulesoftLogo,
                studykikLogo,}} />],
              ["The Team", <AccountMultiple />, <List items={members} images={{
                gonzoImage,
                gabeImage,
                garrettImage,
                gavinImage}} />],
            ]}
          />
        </div>
        <div className={classes.text}>
          <Typography paragraph gutterBottom variant="body1" component="span">
            {postamble}
          </Typography>
          <Typography
            paragraph
            color="primary"
            gutterBottom
            variant="h5"
            component="span"
          >
            <span className={classes.angles}>&lt;</span>
            <a href={'mailto:' + email}>{email}{" "}</a>
            <span className={classes.angles}>&gt;</span>
          </Typography>
        </div>
      </Page>
    );
  };

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid {
      ...GatsbyImageSharpFluid
     }
   }
}
`;

export const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    gonzoImage: file(relativePath: { regex: "/headshots/gonzo.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    garrettImage: file(relativePath: { regex: "/headshots/garrett.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    gabeImage: file(relativePath: { regex: "/headshots/gabriel.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    gavinImage: file(relativePath: { regex: "/headshots/gavin.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    avLogo: file(relativePath: { regex: "/clients/animalventures.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    allscriptsLogo: file(relativePath: { regex: "/clients/allscripts.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    apmLogo: file(relativePath: { regex: "/clients/apmmusic.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    appuriLogo: file(relativePath: { regex: "/clients/appuri.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    mulesoftLogo: file(relativePath: { regex: "/clients/mulesoft.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    studykikLogo: file(relativePath: { regex: "/clients/studykik.png/" }, sourceInstanceName: { eq: "images" }) {
      ...fluidImage
    }
    Basic: site {
      siteMetadata {
        domain
        company
        defaultTitle
        preamble
        defaultDescription
        postamble
        contact {
          email
        }
      }
    }
    Projects: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/projects/" } }
          sort: { fields: [frontmatter___order]}
        ) {
          edges {
            node {
              html
              frontmatter {
                title
                siteLink
                imageLink
                customWidth
                customTopPadding
                imageMaxWidth
                imageTopMargin
                order
              }
            }
          }
        }
    Products: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___order]}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            siteLink
            imageLink
            customWidth
            customTopPadding
            order
          }
        }
      }
    }
    Services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___order]}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            imageLink
            order
          }
        }
      }
    }
    Members: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/members/" } }
      sort: { fields: [frontmatter___order]}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            imageLink
            email
            imageMaxWidth
            imageHeight
            order
          }
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles, { withTheme: true })(Home));
