import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Img from 'gatsby-image';

const styles = theme => ({
    grid: {
    },
    card: {
      height: "100%",
    },
    cardMedia: {
      height: "150px",
    },
    image: {
      margin: "0 auto",
    },
    imageWrapper: {
    },
    titleText: {
      textAlign: "center",
    },
    imageLink: {
      border: 0,
      width: "80%",
      paddingTop: "40px",
      paddingBottom: "20px",
    },
  }),
  Link = props =>
    props.siteLink ? (
      <a href={props.siteLink}>{props.children}</a>
    ) : (
      <>{props.children}</>
    ),
  List = props => {
    const { classes } = props;

    const renderCard = (imageLink, imageMaxWidth, imageHeight, imageTopMargin) =>
    (imageLink in props.images) ? (
      <div className={classes.imageWrapper}>
        <Img 
          fluid={props.images[imageLink].childImageSharp.fluid}
          style={{ maxWidth: imageMaxWidth, height: imageHeight, marginTop: imageTopMargin }}
          className={classes.image}/>
      </div>
    ) : (
      <CardMedia image={imageLink} className={classes.cardMedia} />
    )


    return (
      <Grid
        spacing={16}
        container
        justify="space-around"
        /*alignItems="stretch"*/
        className={classes.grid}
      >
        {props.items.map(edge => {
          const {
            node: {
              html,
              frontmatter: {
                title,
                email,
                siteLink,
                imageLink,
                customWidth,
                customTopPadding,
                imageMaxWidth,
                imageHeight,
                imageTopMargin
              },
            },
          } = edge;
          const itemCols = email ? {
                xs: 6,
                sm: 3,
                md: 3
              } : {
                xs: 12,
                sm: 6,
                md: 4
              }
          return (
            <Grid item xs={itemCols.xs} sm={itemCols.sm} md={itemCols.md} key={title}>
              <Card className={classes.card}>
                {siteLink ? (
                  undefined
                ) : renderCard(imageLink, imageMaxWidth, imageHeight, imageTopMargin) }
                <CardContent>
                  {siteLink ? (
                    <center>
                      <Link siteLink={siteLink}>
                        <img
                          src={imageLink}
                          alt={title}
                          className={classes.imageLink}
                          style={{
                            paddingTop: customTopPadding,
                            width: customWidth,
                          }}
                        />
                      </Link>
                    </center>
                  ) : (
                    <div>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.titleText}
                    >
                      <Link siteLink={siteLink}>{title}</Link>
                    </Typography>
                    {(email) ?
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="h4"
                      className={classes.titleText}
                    >
                      {email}
                    </Typography>
                    : undefined
                    }
                    </div>
                  )}
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

export default withStyles(styles)(List);
